
import { computed, defineComponent, getCurrentInstance, reactive, ref, toRefs } from 'vue'
import { CountTo } from 'vue3-count-to'

export default defineComponent({
  components: {
    CountTo
  },
  setup() {
    const { ctx } = getCurrentInstance() as any
    const state = reactive({
      setStartVal: 0,
      setEndVal: 2017,
      setDuration: 4000,
      setDecimals: 0,
      setSeparator: '',
      setSuffix: ' rmb',
      setPrefix: '¥ '
    })
    const stateAsRefs = toRefs(state)
    const startVal = computed(() => {
      if (state.setStartVal) {
        return state.setStartVal
      } else {
        return 0
      }
    })
    const endVal = computed(() => {
      if (state.setEndVal) {
        return state.setEndVal
      } else {
        return 0
      }
    })
    const duration = computed(() => {
      if (state.setDuration) {
        return state.setDuration
      } else {
        return 0
      }
    })
    const decimals = computed(() => {
      if (state.setDecimals) {
        if (state.setDecimals < 0 || state.setDecimals > 20) {
          alert('digits argument must be between 0 and 20')
          return 0
        }
        return state.setDecimals
      } else {
        return 0
      }
    })
    const separator = computed(() => {
      return state.setSeparator
    })
    const suffix = computed(() => {
      return state.setSuffix
    })
    const prefix = computed(() => {
      return state.setPrefix
    })
    const countRef = ref(null)
    const startCount = () => {
      console.log(ctx.$refs)
      // ctx.$refs.count && (ctx.$refs.count as any).start()
    }
    const pauseResume = () => {
      // countRef.value && (countRef.value as any).pauseResume()
    }
    return {
      startVal,
      endVal,
      duration,
      decimals,
      separator,
      suffix,
      prefix,
      countRef,
      startCount,
      pauseResume,
      ...state,
      ...stateAsRefs
    }
  }
})
