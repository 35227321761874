<template>
  <div class="draggableList">
    <div
      :style="{width: list1width}"
      class="draggableList-list"
    >
      <h3>{{ list1Title }}</h3>
      <draggable
        :list="list1"
        group="article"
        class="dragArea"
      >
        <div
          v-for="element in list1"
          :key="element.id"
          class="list-complete-item"
        >
          <div class="list-complete-item-handle">
            {{ element.id }}[{{ element.author }}] {{ element.title }}
          </div>
          <div style="position:absolute;right:0px;">
            <span
              @click="deleteEle(element)"
              style="float: right ;margin-top: -20px;margin-right:5px;"
            >
              <i
                style="color:#ff4949"
                class="el-icon-delete"
              />
            </span>
          </div>
        </div>
      </draggable>
    </div>
    <div
      :style="{width: list2width}"
      class="draggableList-list"
    >
      <h3>{{ list2Title }}</h3>
      <draggable
        :list="list2"
        group="article"
        class="dragArea"
      >
        <div
          v-for="element in list2"
          :key="element.id"
          class="list-complete-item"
        >
          <div
            @click="pushEle(element)"
            class="list-complete-item-handle2"
          >
            {{ element.id }} [{{ element.author }}] {{ element.title }}
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import { ArticleModel } from '@/model/articleModel'
export default defineComponent({
  components: {
    draggable: VueDraggableNext
  },
  props: {
    list1: {
      type: [] as PropType<Array<ArticleModel>>,
      default: () => {
        return []
      }
    },
    list2: {
      type: [] as PropType<Array<ArticleModel>>,
      default: () => {
        return []
      }
    },
    list1Title: {
      type: String,
      default: 'list1'
    },
    list2Title: {
      type: String,
      default: 'list2'
    },
    list1width: {
      type: String,
      default: '48%'
    },
    list2width: {
      type: String,
      default: '48%'
    }
  },
  setup(props) {
    const isNotInList1 = (v: ArticleModel) => {
      return props.list1.every((k: any) => v.id !== k.id)
    }
    const isNotInList2 = (v: ArticleModel) => {
      return props.list2.every((k: any) => v.id !== k.id)
    }
    const deleteEle = (ele: ArticleModel) => {
      for (const item of props.list1) {
        if (item.id === ele.id) {
          const index = props.list1.indexOf(item)
          // eslint-disable-next-line vue/no-mutating-props
          props.list1.splice(index, 1)
          break
        }
      }
      if (isNotInList2(ele)) {
        // eslint-disable-next-line vue/no-mutating-props
        props.list2.unshift(ele)
      }
    }
    const pushEle = (ele: ArticleModel) => {
      for (const item of props.list2) {
        if (item.id === ele.id) {
          const index = props.list2.indexOf(item)
          // eslint-disable-next-line vue/no-mutating-props
          props.list2.splice(index, 1)
          break
        }
      }
      if (isNotInList1(ele)) {
        // eslint-disable-next-line vue/no-mutating-props
        props.list1.push(ele)
      }
    }
    return {
      deleteEle,
      pushEle,
      isNotInList1,
      isNotInList2
    }
  }
})

</script>

<style lang="scss" scoped>
.draggableList {
  background: #fff;
  padding-bottom: 40px;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .draggableList-list {
    float: left;
    padding-bottom: 30px;

    &:first-of-type {
      margin-right: 2%;
    }

    .dragArea {
      margin-top: 15px;
      min-height: 50px;
      padding-bottom: 30px;
    }
  }
}

.list-complete-item {
  cursor: pointer;
  position: relative;
  font-size: 14px;
  padding: 5px 12px;
  margin-top: 4px;
  border: 1px solid #bfcbd9;
  transition: all 1s;
}

.list-complete-item-handle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 50px;
}

.list-complete-item-handle2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
}

.list-complete-item.sortable-chosen {
  background: #4AB7BD;
}

.list-complete-item.sortable-ghost {
  background: #30B08F;
}

.list-complete-enter,
.list-complete-leave-active {
  opacity: 0;
}
</style>
