
import { defineComponent, reactive, toRefs } from 'vue'
import draggableSelect from '@/components/draggable-select/Index.vue'

export default defineComponent({
  components: {
    draggableSelect
  },
  setup() {
    const state = reactive({
      value: ['Apple', 'Banana', 'Orange'],
      options: [
        {
          value: 'Apple',
          label: 'Apple'
        },
        {
          value: 'Banana',
          label: 'Banana'
        },
        {
          value: 'Orange',
          label: 'Orange'
        },
        {
          value: 'Pear',
          label: 'Pear'
        },
        {
          value: 'Strawberry',
          label: 'Strawberry'
        }
      ],
      changeVal: (val: any) => {
        state.value = val
      }
    })
    return {
      ...toRefs(state)
    }
  }
})
